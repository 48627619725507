<template>
  <div class="w-full">
    <label :for="name" class="leading-7 text-sm font-medium text-gray-500">
      {{ label }}
    </label>
    <textarea
      :name="name"
      :placeholder="placeholder"
      :value="value"
      class="w-full bg-white rounded-md border border-white focus:border-primary-500 text-base outline-none appearance-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["input"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    value: String
  }
})
export default class MTextArea extends Vue {
  label = "";
  name = "";
  placeholder = "";
  value = "";
}
</script>
