
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["input"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    value: String
  }
})
export default class MTextArea extends Vue {
  label = "";
  name = "";
  placeholder = "";
  value = "";
}
